import React from "react";
import "./Home2.css";
import Map from "../Map/Map";

export default function Home3() {
  return (
    <>
      <Map />
    </>
  );
}
