import React from "react";
import "./Home2.css";
import Contact from "../Contact/Contact";
import { Container } from "react-bootstrap";

export default function Home4() {
  return (
    <Container>
      <Contact />
    </Container>
  );
}
